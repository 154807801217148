import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

import Sortable from "sortablejs"

export default class extends Controller {
  static targets = ["messageBoxError", "addBtn"]


  connect() {
  }

    createPF(){
      let providers = [...document.querySelectorAll('.selectedProvider')].map(x => x.innerHTML)
      let messageBoxError = this.messageBoxErrorTarget
      let processFlowNameInput = document.getElementById("process-flow-name").value
      let setDefault =  document.getElementById("set_default").checked

      let data = new FormData()
      data.append("providers_name", providers)
      data.append("process_flow_name", processFlowNameInput)
      data.append("set_defaut", setDefault)

      if (processFlowNameInput == ""){
        messageBoxError.classList.remove('invisible')
        document.getElementById("msg-content-add-error").innerHTML = "Please enter a process flow name"
        setTimeout(() => { messageBoxError.classList.add('invisible'); }, 5000);
      } else {

        Rails.ajax({
          url: '/process_flows',
          type:'POST',
          data:  data,
          success: function(data) {

            if (data.success){
              window.location.href = "/account?tab=process-flows";
            }  else {
              messageBoxError.classList.remove('invisible')
              document.getElementById("msg-content-add-error").innerHTML = data.message
              setTimeout(() => { messageBoxError.classList.add('invisible'); }, 5000);
            }

          }
        })

      }
    }

   updatePF(){

     function getMetaValue(){
       const element = document.head.querySelector('meta[name="csrf-token"]')
       return element.getAttribute("content")
     }

    let providers = [...document.querySelectorAll('.selectedProvider')].map(x => x.innerHTML)
    let messageBoxError = this.messageBoxErrorTarget
    let processFlowNameInput = document.getElementById("process-flow-name").value
    let setDefault =  document.getElementById("set_default").checked
    let pfId = this.addBtnTarget.id

    let data = new FormData()
    data.append("providers_name", providers)
    data.append("process_flow_name", processFlowNameInput)
    data.append("set_defaut", setDefault)

    if (processFlowNameInput == ""){
      messageBoxError.classList.remove('invisible')
      document.getElementById("msg-content-add-error").innerHTML = "Please enter a process flow name"
      setTimeout(() => { messageBoxError.classList.add('invisible'); }, 5000);
    } else {

      Rails.ajax({
        url: "/process_flows/" + pfId,
        type: 'PUT',
          dataType: 'script',
          credentials: "include", headers: {
          "X-CSRF-Token": getMetaValue("csrf-token")
        },
        data: data,
        success: function(data) {
          if (data.success){
            window.location.href = "/account?tab=process-flows";
          }  else {
          console.log(this)
            messageBoxError.classList.remove('invisible')
            document.getElementById("msg-content-add-error").innerHTML = data.message
            setTimeout(() => { messageBoxError.classList.add('invisible'); }, 5000);
          }

        }
      })

    }
  }


}
